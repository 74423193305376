import {
  DOCK5F_DRILL,
  DOCK6F_DRILL,
  ELECTRONIC_SAW,
  ENGRAVING,
  LABELING,
  LABEL_IMAGE,
  PTP,
  SIDE_HOLE,
} from '@/util/configure'

// 文件管理每个key的含义
export const fileManageFormTextObj = {
  setting_id: '生产线',
  nameArr: '一级文件夹',
  orderCodeAsDirectionName: '导出加工文件按订单号命名',
  [ENGRAVING]: '雕刻机',
  [ELECTRONIC_SAW]: '电子锯',
  [LABELING]: '贴标机',
  [SIDE_HOLE]: '侧孔机',
  [DOCK5F_DRILL]: '五面钻',
  [DOCK6F_DRILL]: '六面钻',
  [PTP]: 'PTP',
  [LABEL_IMAGE]: '贴标图片',
  merge_folder_list: '文件夹合并',
  merge_folder_display_name: '合并名称',
  platePropertyMergeFile: '按照材质颜色厚度合并到子文件夹',
  delimiter: '分隔符',
  fileStartNum: '文件夹数自增起始值',
  subfolderNameStr: '子文件夹合并名称',
}
