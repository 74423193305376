<template>
  <div class="c-cell">
    <slot name="label">
      <div :class="{ label: !isSub }">
        <span v-if="isRequired" style="color: red">*</span> {{ label }}
      </div>
    </slot>
    <div
      :style="{
        marginLeft: translateGap(gap) + 'px',
      }"
      class="inline-block mr15"
    >
      <slot name="value">
        <div class="value">{{ value }}</div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    gap: {
      type: Number,
      default: 0,
    },
    isSub: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    translateGap(value) {
      return parseInt(value)
    },
  },
}
</script>

<style scoped lang="less">
.c-cell {
  display: flex;
  align-items: center;
  .label {
    width: 90px;
    text-align: right;
    &::after {
      content: ':';
      margin: 0 8px 0 2px;
    }
  }
}
</style>
