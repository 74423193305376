<template>
  <div class="c-page-mask-loading" v-if="showLoading">
    <a-spin>
      <a-icon slot="indicator" type="loading" style="font-size: 60px" spin />
    </a-spin>
  </div>
</template>

<script>
export default {
  props: {
    showLoading: {
      type: Boolean,
    },
  },
}
</script>

<style scoped lang="less">
.c-page-mask-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #0008;
  z-index: 2005;
}
</style>
