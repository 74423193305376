<template>
  <div class="c-drop-input">
    <div class="tag-box">
      <div class="custom-form-head">
        <span>数据标签池</span>
        <span class="ml10">可拖拽标签池内标签</span>
      </div>
      <div
        class="custom-form-main"
        v-for="(item, index) in tagList"
        :key="index"
      >
        <div class="tag-item">
          <span v-if="item.title" style="margin-right: 3px"
            >{{ item.title }}:</span
          >
          <div class="tags">
            <span
              v-for="(items, indexs) in item.arr"
              :key="indexs + '1'"
              :class="`tag-item${indexs}`"
              @dragstart="startDrag($event, items)"
              @click="copyTag(indexs)"
              data-clipboard-action="copy"
              :data-clipboard-text="'{' + items.label_name_show + '}'"
              :style="isAllowDrag(items.disabled_drag)"
              :draggable="disabled ? !disabled : !items.disabled_drag"
              >{{ items.label_name_show }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="custom-file-name">
      <div :class="{ flex: !isSub }">
        <Cell :label="label" :is-sub="isSub" :is-required="isSub">
          <template #value>
            <div
              @dragstart.prevent
              @drop="handleDropOver($event)"
              @dragover="handleDragOver($event)"
              :ref="`format_table_input`"
              class="file-format-box value border-c-d br4 pt4 pb4 pl11 flex"
            >
              <div v-for="(item, index) in nameArr" :key="index">
                <span class="tag" :style="isAllowDrag()">
                  {{ item }}
                  <i
                    v-if="!disabled"
                    class="el-icon-close"
                    @click="handleRemoveItem(index)"
                  ></i>
                </span>
              </div>
              <div v-if="nameArr.length == 0" class="color-b">
                请拖选标签池内标签
              </div>
            </div>
          </template>
        </Cell>
        <slot></slot>
      </div>
      <slot name="fileNameLimit"></slot>
      <Cell
        v-if="showExample"
        style="margin-bottom: 16px; color: #999"
        label="样例"
        :value="example"
      />
    </div>
  </div>
</template>

<script>
import Cell from '@/components/cell.vue'

export default {
  data() {
    return {
      nameArr: [],
      dragItem: {},
    }
  },
  components: {
    Cell,
  },
  props: {
    disabled: {
      default: false,
    },
    label: {
      type: String,
      default: '一级文件夹',
    },
    tagList: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
    },
    exampleMap: {
      type: Object,
    },
    showExample: {
      type: Boolean,
      default: true,
    },
    isSub: {
      type: Boolean,
      default: false,
    },
    delimiter: {
      type: String,
      default: '',
    },
  },
  computed: {
    example() {
      const arr = this.nameArr.map((item) => {
        return this.exampleMap[item]
      })
      return arr.join(this.$props.delimiter)
    },
  },
  created() {
    this.nameArr = this.value
  },
  watch: {
    value() {
      this.nameArr = this.value
    },
    nameArr() {
      this.$emit('input', this.nameArr)
    },
  },
  methods: {
    isAllowDrag(disabledFlag) {
      const dragStyleMap = {
        disabled: 'background: #e9e9e9;cursor: not-allowed;',
        normal: 'background: #eaf4f6;cursor: pointer;',
      }

      const isDisabled = this.disabled || disabledFlag

      return isDisabled ? dragStyleMap.disabled : dragStyleMap.normal
    },
    handleDropOver(event) {
      event.target.focus()
      this.$nextTick(() => {
        event.preventDefault()
        this.nameArr.push(this.dragItem.label_name_show)
      })
    },
    // 拖动放入时, 阻止事件冒泡
    handleDragOver(event) {
      event.preventDefault()
    },
    // 拖拽标签
    startDrag(event, item) {
      if (item.disabled_drag) {
        event.preventDefault()
      } else {
        this.dragItem = item
      }
    },
    // 复制标签
    copyTag(index) {
      var clipboard = new this.$copy(`.tag-item${index}`)
      clipboard.on('success', () => {
        this.$message({
          type: 'success',
          message: '复制成功!',
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message({
          type: 'success',
          message: '复制失败, 请尝试拖拽标签!',
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
    },
    handleRemoveItem(index) {
      this.nameArr.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="less">
.c-drop-input {
  box-sizing: border-box;
  .tag-box {
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    .custom-form-head {
      box-sizing: border-box;
      height: 44px;
      padding: 11px 24px;
      background: #e9e9e9;
      span:first-child {
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
      span:last-child {
        color: #8c8c8c;
        font-size: 14px;
      }
    }
    .custom-form-main {
      box-sizing: border-box;
      padding: 0 24px;
      &:last-child .tag-item {
        border-bottom: none;
      }
      .tag-item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 29px 0 21px 0;
        border-bottom: 1px solid #e9e9e9;
        & > span {
          display: inline-block;
          width: 128px;
          font-size: 16px;
          text-align: right;
          user-select: none;
        }
        .tags {
          display: flex;
          align-items: center;
          & > span {
            display: inline-block;
            min-width: 102px;
            height: 32px;
            margin-right: 8px;
            color: #5a5a5a;
            font-size: 14px;
            line-height: 32px;
            text-align: center;
          }
        }
      }
    }
  }
  .custom-file-name {
    margin-top: 24px;
    & > div {
      margin-bottom: 16px;
    }
    .file-has-delimiter {
      .ant-input {
        width: 100px;
      }
    }
    .file-name-format {
      display: flex;
      align-items: center;
    }
  }
  .file-format-box {
    min-width: 200px;
    min-height: 42px;
    padding-right: 24px;
    line-height: 32px;

    .default-show {
      padding-left: 8px;
    }
    .tag {
      display: inline-block;
      min-width: 102px;
      height: 32px;
      margin-right: 8px;
      color: #5a5a5a;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      background: #eaf4f6;
      & > i {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
