<template>
  <div class="file-manage" style="position: relative">
    <header class="border-c-e border-w-2">
      <a-button
        style="margin-left: 6px"
        @click="saveConf"
        type="primary"
        :loading="saveConfBtnLoading"
      >
        <i><a-icon type="save" /></i>{{ $t('common.saveSetting') }}</a-button
      >
    </header>
    <div class="content" ref="contentRef">
      <!-- 生产线 -->
      <div class="product-line">
        <span class="title"
          >{{ $t('common.productionLine') }}：
          <a-button
            v-for="item in productList"
            :key="item.id"
            style="margin-right: 10px; margin-bottom: 10px"
            :type="Number(activeProductId) === item.id ? 'primary' : ''"
            @click="handleProduct(item)"
            >{{ item.name }}</a-button
          >
        </span>
      </div>
      <div class="rename-config" style="margin-top: 12px">
        <div class="title">{{ $t('fileManagerPage.folderNameSetting') }}</div>
        <DropInput
          :tagList="tagList"
          style="margin-top: 8px"
          v-model="nameArr"
          :exampleMap="exampleMap"
          :disabled="form.orderCodeAsDirectionName"
        >
          <el-checkbox
            class="ml10 mt10"
            v-model="form.orderCodeAsDirectionName"
            >{{ $t('fileManagerPage.exportNameSetting') }}</el-checkbox
          >
          <p
            slot="fileNameLimit"
            style="margin-left: 48px; color: rgb(153, 153, 153)"
          >
            {{ $t('fileManagerPage.fileMax')
            }}<a-input
              style="width: 80px; margin: 0 5px"
              v-model="form.max_file_name"
              @input="handleCheckInput"
            ></a-input
            >{{ $t('fileManagerPage.char') }}，{{
              $t('fileManagerPage.overTip')
            }}
          </p>
        </DropInput>
        <div class="list-set">
          <a-form-model :rules="rules" :model="form" ref="form">
            <a-form-model-item
              v-for="(item, index) in setList"
              :key="index"
              :prop="item.type"
            >
              <a-checkbox
                v-model="item.isFolder"
                @change="handleClickCheckBox(item)"
                :id="`fileManage_isFolder_checkbox_${item.text}`"
                :disabled="
                  form.merge_folder_list.indexOf(item.text + '文件') !== -1
                "
                class="file_checkbox"
                >{{ item.text }}{{ $t('common.folder') }}:</a-checkbox
              >
              <a-input
                v-model="form[item.type]"
                :disabled="!item.isFolder"
                :maxLength="20"
                :id="`fileManage_fileName_input_${item.text}`"
                style="max-width: 360px"
                @blur="handleBlur(item.type)"
              />
            </a-form-model-item>
            <!-- 加这个的原因是因为部分结构在以前的代码中是嵌入到表单中的，临时需求需要尽快上线所以不调整结构直接添加一个a-form-model-item来隔开 -->
            <a-form-model-item></a-form-model-item>
            <div class="title common-box">打孔文件设置</div>
            <div></div>
            <div class="common-box w100">
              <span class="mr10">打孔文件按不同订单分文件夹单独放置</span>
              <a-checkbox
                v-model="form.output56FDrillSortByCode"
                id="fileManage_output56FDrillSortByCode_checkbox"
              ></a-checkbox>
              <span>（对内嵌柜柜客户端的云排版不生效）</span>
            </div>
            <!-- 占位用 -->
            <div></div>
            <!-- 直接用同一个表单 -->
            <div class="title">
              {{ $t('fileManagerPage.folderMergeSetting') }}
            </div>
            <!-- 占位用 -->
            <div></div>
            <a-form-model-item
              :label="translateLang('fileManagerPage.folderMerge')"
              prop="merge_folder_list"
              style="margin-top: 8px"
            >
              <a-select
                v-model="form.merge_folder_list"
                default-value="a1"
                mode="multiple"
              >
                <a-select-option v-for="item in fileMergeOPtions" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 占位用 -->
            <br />
            <a-form-model-item
              :label="translateLang('fileManagerPage.mergeName')"
              prop="merge_folder_display_name"
              v-if="form.merge_folder_list.length > 1"
            >
              <a-input
                v-model="form.merge_folder_display_name"
                @input="handleFileName"
                @blur="handleFileName"
                :maxLength="20"
              />
            </a-form-model-item>
            <br />
            <div v-if="form.merge_folder_list.length > 1" class="mb20">
              <span class="inline-block mr20 relative" ref="subFileRef">
                <div
                  v-show="userInfo.id !== 38370"
                  class="subfolder-tip absolute pv1 ph5 br5 r20"
                  style="background-color: #f9e0c7; line-height: 20px"
                >
                  {{ $t('main.sideBar.limitDate') }}
                </div>
                {{ $t('fileManagerPage.subMergeFolder') }}:</span
              >
              <a-switch
                :class="{ ml20: userInfo.id !== 38370 }"
                @click="handleClickSwitch"
                v-model="form.platePropertyMergeFile"
              ></a-switch>
            </div>
            <br />
          </a-form-model>
          <DropInput
            :tagList="subFolderTagList"
            style="margin-top: 8px; width: 100%"
            v-model="subfolderNameArr"
            :label="translateLang('fileManagerPage.subFolderName')"
            :isSub="true"
            :exampleMap="exampleMap"
            :delimiter="form.delimiter"
            :disabled="form.orderCodeAsDirectionName"
            v-if="
              form.platePropertyMergeFile && form.merge_folder_list.length > 1
            "
          >
            <!-- :showExample="false" -->
            <span v-show="isSubErr" style="color: red"
              >({{ $t('fileManagerPage.mergeDataTip') }})</span
            >
            <div class="folder-format-box mt20">
              <span
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.delimiter')
                }}:</span
              >
              <a-input
                class="split-char ml10 mr5"
                :class="{ 'empty-err': !form.delimiter.length }"
                @input="handleinputSplitChar"
                v-model="form.delimiter"
              ></a-input>
              <span class="color-a">{{ $t('fileManagerPage.splitTip') }}</span>
            </div>
            <div class="folder-format-box mt20">
              <span>{{ $t('fileManagerPage.folderAutoAdd') }}:</span>
              <a-input
                id="labellingSetting_start_number_input"
                class="split-char ml10 mr5"
                @blur="handleInputBlur"
                @input="handleInputNumber"
                v-model="form.fileStartNum"
              ></a-input>
              <span class="color-a"
                >({{ $t('fileManagerPage.resetTip') }})</span
              >
            </div>
            <!-- <div class="folder-format-box mt20">
              <span>自定义合并子文件夹名(示例):</span>
              <span style="white-space: pre">{{ exampleName }}</span>
            </div> -->
          </DropInput>
        </div>
      </div>
    </div>
    <PageMaskLoading :showLoading="showPageLoading" />
  </div>
</template>

<script>
import { getElectronicSawLabel } from '@/apis/tag'
import DropInput from '@/components/dropInput/dropInput'
import PageMaskLoading from '@/components/pageMaskLoading.vue'
import { translate } from '@/util/commonFun'
import { buryPointApi, genFinalRecord } from '@/util/commonFuncs'
import {
  AIO_FOLDER,
  DOCK5F_DRILL,
  DOCK6F_DRILL,
  ELECTRONIC_SAW,
  ENGRAVING,
  LABELING,
  LABEL_IMAGE,
  PTP,
  SIDE_HOLE,
  fileNameSetObj,
  folderKeyList,
} from '@/util/configure'
import { fileManageFormTextObj } from '@/util/operateRecordConfigure/fileManageConfigure'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      ruleNameList: [],
      // oriOrderCodeAsDirectionName: null,
      selectedFolder: [], //选中可合并文件夹
      productList: [],
      nameArr: [],
      oriNameArr: [], // 获取的原始一级文件夹
      subfolderNameArr: [],
      activeProductId: 0,
      activeProductName: '', // 生产线名字
      showPageLoading: false,
      tagList: [
        {
          title: '',
          arr: [
            {
              label_name_show: '项目地址',
              label_name: 'address',
              disabled_drag: false,
            },
            {
              label_name_show: '订单号',
              label_name: 'orderNo',
              disabled_drag: false,
            },
            {
              label_name_show: '排版时间',
              label_name: 'typesettingTime',
              disabled_drag: false,
            },
          ],
        },
      ],
      subFolderTagList: [],
      exampleMap: {
        项目地址: '北京天安门104号',
        订单号: '220107_101',
        排版时间: '2022/01/07 00:00:00',
        板件颜色: 'T01',
        板件类型: '多层实木',
        板件厚度: '18',
        板件长宽: '2440 * 1220',
        输出日期: '0101',
        文件数自增: '0001',
      },
      rules: {
        merge_folder_display_name: [
          {
            required: true,
            message: '信息不能为空',
            trigger: 'blur',
          },
        ],
        subfolderName: [
          {
            required: true,
            message: '信息不能为空',
            trigger: 'blur',
          },
        ],
      },
      setList: [
        {
          text: fileNameSetObj[ENGRAVING], // 雕刻机
          type: ENGRAVING,
          canMerge: true, // 是否可以合并
          isFolder: true, // 是否需要存放当前文件夹
        },
        {
          text: fileNameSetObj[ELECTRONIC_SAW], // 电子锯
          type: ELECTRONIC_SAW,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[LABELING], // 贴标机
          type: LABELING,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[SIDE_HOLE], // 侧孔机
          type: SIDE_HOLE,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[DOCK5F_DRILL], // 五面钻
          type: DOCK5F_DRILL,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[DOCK6F_DRILL], // 六面钻
          type: DOCK6F_DRILL,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[PTP], // PTP
          type: PTP,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[LABEL_IMAGE], // 贴标图片
          type: LABEL_IMAGE,
          canMerge: true,
          isFolder: true,
        },
        {
          text: fileNameSetObj[AIO_FOLDER], // 一体机文件
          type: AIO_FOLDER,
          canMerge: true,
          isFolder: true,
        },
      ],
      form: {
        merge_folder_list: [], // 文件夹合并
        merge_folder_display_name: '', // 合并文件夹名称
        orderCodeAsDirectionName: false, //导出加工文件按订单号命名
        max_file_name: 60, // 文件名长度
        required_folders: [], //选中需要文件夹的数据
        platePropertyMergeFile: false, // 是否合并子文件夹
        subfolderName: '', // 子文件夹名称
        delimiter: '', //文件夹名称分割符
        fileStartNum: '0001', //文件夹数量起始值
        output56FDrillSortByCode: false,
      },
      oriForm: {}, // 原始form内容
      saveConfBtnLoading: false,
      fileMergeOPtions: [], // 合并文件选项
      oriSettingId: '', // 原始生产线id
      dropData: {},
      nameMap: new Map([
        ['big_plank_info', '大板信息'],
        ['other_info', '其他信息'],
      ]),
      isSubErr: false,
    }
  },
  components: {
    DropInput,
    PageMaskLoading,
  },
  computed: {
    ...mapState(['userInfo', 'ncLabel']),
    // 生成示例
    exampleName() {
      let str = this.form.label_image_name_type
        ? Array.from(this.form.label_image_name_type)
            .map((val) => {
              return this.defaulTagValue[val]
            })
            .join(this.form.delimiter)
        : ''
      return str
    },
  },
  async created() {
    this.setList.forEach((item) => {
      if (item.canMerge) {
        this.fileMergeOPtions.push(item.text + translate('common.file'))
      }
      this.$set(this.form, item.type, '')
      this.$set(this.rules, item.type, [
        {
          validator: (rule, value, callback) => {
            this.ruleNameList = []
            this.setList.forEach((item) => {
              if (this.form[item.type] === value) {
                this.ruleNameList.push(this.form[item.type])
              }
            })
            if (!value) {
              callback(new Error(translate('fileManagerPage.emptyErrTip')))
            } else if (this.ruleNameList.length > 1) {
              callback(new Error(translate('fileManagerPage.repeatErrTip')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      ])
    })
    // 增加fileMergeOPtions的值
    this.fileMergeOPtions = this.fileMergeOPtions.concat([
      `${translate('common.layoutImg')}1${translate('common.file')}`,
      `${translate('common.layoutImg')}2${translate('common.file')}`,
    ])

    try {
      this.productList = await this.getProductList()
    } catch (error) {
      console.error(error)
    }
    if (this.productList.length) {
      // 在这里需要判断路由上是否携带了id，如果携带了id，需要使用路由上的id
      this.getCustomizeFolderSetting(
        this.$route.query.setting_id ?? this.productList[0].id
      )
      this.oriSettingId = this.$route.query.setting_id ?? this.productList[0].id
    }
  },
  watch: {
    nameArr: {
      handler(newVal) {
        const shallowCloneArr = { ...this.tagList[0] }['arr']
        shallowCloneArr.forEach((item) => {
          item.disabled_drag = newVal.includes(item.label_name_show)
        })
        Object.assign({ ...this.tagList[0] }['arr'], shallowCloneArr)
      },
      immediate: true,
    },
    subfolderNameArr: {
      // 判断需要新增板件长宽
      handler(val) {
        this.dealSubFolderList(val)
        this.form['subfolderNameStr'] = val.join(',')
        // && val.includes('板件长宽')
        this.isSubErr =
          !val.length ||
          !(
            (val.includes('板件颜色') &&
              val.includes('板件类型') &&
              val.includes('板件厚度') &&
              val.includes('板件长宽')) ||
            val.includes('文件数自增')
          )
      },
      immediate: true,
    },
    'form.merge_folder_list': {
      handler(val) {
        if (val?.length && val.length > 1) {
          this.rules.merge_folder_display_name.required = true
        } else {
          this.rules.merge_folder_display_name.required = false
        }
      },
    },
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    handleClickSwitch(checked) {
      if (checked) {
        buryPointApi('fileManagement', 'merge_to_subfolders')
      }
    },
    dealSubFolderList(val) {
      this.subFolderTagList.forEach((listItem) => {
        listItem.arr.forEach((item) => {
          item.disabled_drag = val.includes(item.label_name_show)
        })
      })
    },
    // 输入分隔符
    handleinputSplitChar() {
      // 限制输入特殊符号
      const reg = /[\\<>:|./?*"]/g
      this.form.delimiter = this.form.delimiter.replace(reg, '')
      if (this.form.delimiter.length > 10) {
        this.form.delimiter = this.form.delimiter.slice(0, 10)
      }
    },
    // 贴标序号起始数值鼠标失去光标
    handleInputBlur() {
      if (!this.form.fileStartNum) {
        this.form.fileStartNum = '0001'
      }
    },
    // 输入贴标序号起始数值
    handleInputNumber() {
      const reg = /[^0-9]/g
      this.form.fileStartNum = this.form.fileStartNum.replace(reg, '')
      if (this.form.fileStartNum.length > 4) {
        this.form.fileStartNum = this.form.fileStartNum.slice(0, 4)
      }
    },
    handleBlur(type) {
      this.$refs.form.validateField(type)
    },
    // 获取文件夹合并数据
    getFileMergeOPtions() {
      // 清空上一次的数据，获取新数据
      this.form.required_folders = []
      this.setList.forEach((item) => {
        if (item.canMerge) {
          this.form.required_folders.push(item.type)
        }
      })
    },
    // 点击选中文件夹
    handleClickCheckBox(selectedItem) {
      for (let item of this.setList) {
        if (item.type === selectedItem.type) {
          if (selectedItem.isFolder) {
            item.canMerge = true
          } else {
            item.canMerge = false
          }
        }
      }
      this.getFileMergeOPtions()
    },
    // 获取生产线列表
    getProductList() {
      return new Promise((resolve, reject) => {
        this.$getByToken('/get_production_line_setting', '', (res) => {
          if (res.status == 1) {
            resolve(res.data)
            // 需要先判断query里面有没有生产线id
            this.activeProductId =
              this.$route.query.setting_id ??
              (res.data.length && res.data[0].id)
            // 下面的name需要和id对应
            this.activeProductName = this.$route.query.setting_id
              ? res.data.find(
                  (item) => item.id === Number(this.$route.query.setting_id)
                ).name
              : res.data.length && res.data[0].name
          } else if (res.result) {
            this.$message({
              type: 'info',
              message: translate('lineManager.permissonTip'),
              duration: 1500,
              offset: 70,
            })
            reject()
          }
        })
      })
    },
    handleProduct(item) {
      this.activeProductName = item.name
      this.activeProductId = item.id
      this.showPageLoading = true
      this.getCustomizeFolderSetting(item.id)
    },
    // 过滤出文件夹命名设置改变的内容
    filterFileNameSetChangeValue() {
      const arr = []
      if (JSON.stringify(this.oriNameArr) !== JSON.stringify(this.nameArr)) {
        // 这儿是判断一级文件夹
        arr.push({
          key: '一级文件夹',
          value: this.nameArr,
        })
      }
      this.setList.forEach((item) => {
        if (this.form[item.type].trim() !== this.oriForm[item.type].trim()) {
          let obj = {
            key: fileNameSetObj[item.type],
            value: this.form[item.type],
          }
          arr.push(obj)
        }
      })
      return arr
    },
    // 过滤出文件夹合并设置的内容
    filterFileNameMergeChangeValue() {
      const arr = []
      if (
        JSON.stringify(this.form.merge_folder_list) !==
        JSON.stringify(this.oriForm.merge_folder_list)
      ) {
        arr.push({
          key: '文件夹合并',
          value: this.form.merge_folder_list,
        })
      }
      if (
        JSON.stringify(this.form.merge_folder_display_name.trim()) !==
        JSON.stringify(this.oriForm.merge_folder_display_name.trim())
      ) {
        arr.push({
          key: '合并名称',
          value: this.form.merge_folder_display_name,
        })
      }
      return arr
    },
    genCompareFormData(current, ori) {
      let compareMsg = [
        {
          title: '文件夹命名设置',
          keys: [
            'nameArr',
            'orderCodeAsDirectionName',
            ENGRAVING,
            ELECTRONIC_SAW,
            LABELING,
            SIDE_HOLE,
            DOCK5F_DRILL,
            DOCK6F_DRILL,
            PTP,
            LABEL_IMAGE,
          ],
        },
        {
          title: '文件夹合并设置',
          keys: [
            'merge_folder_list',
            'merge_folder_display_name',
            'platePropertyMergeFile',
            'delimiter',
            'fileStartNum',
            'subfolderNameStr',
          ],
        },
      ]
      const settingIdValueObj = {}
      this.productList.forEach((child) => {
        settingIdValueObj[child.id] = child.name
      })
      return {
        type: 'form',
        current,
        ori,
        compareMsg,
        formTextObj: fileManageFormTextObj,
        formValueObj: {
          setting_id: settingIdValueObj,
        },
      }
    },
    // 生成最终的操作记录
    genFinalRecordFn(current, ori) {
      let recordData = {
        key: '', // 操作记录最外层的名字，比如：雕刻机
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          {
            key: this.activeProductName,
            dataArr: [
              // 分为表单和table两种
              this.genCompareFormData(current, ori),
            ],
          },
        ],
      }
      genFinalRecord(recordData, 'file_management', () => {
        this.getCustomizeFolderSetting(this.activeProductId)
        this.oriSettingId = this.activeProductId
      })
    },
    saveConf() {
      this.handleFileName()
      this.form.max_file_name = Number(this.form.max_file_name)
        ? Number(this.form.max_file_name)
        : 60
      // 表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dealSubFileName()

          let obj = {
            setting_id: this.activeProductId,
            data: {
              ...this.form,
            },
          }
          if (
            (this.isSubErr &&
              this.form.merge_folder_list.length > 1 &&
              this.form.platePropertyMergeFile) ||
            !this.form.delimiter.length
          ) {
            this.$message.error(
              !this.form.delimiter.length
                ? '请输入正确的分隔符'
                : '请保证已拖选正确的子文件夹名称!'
            )

            this.$refs['contentRef'].scrollTo({
              top: this.$refs['subFileRef'].offsetTop,
              behavior: 'smooth', // 可以使用平滑滚动效果
            })
            return
          }
          if (this.nameArr.length) {
            obj.data.wrapper_folder = this.nameArr
          } else {
            // 如果为空，那么就直接使用项目地址
            obj.data.wrapper_folder = ['项目地址']
          }
          if (this.form.merge_folder_list.length < 2) {
            delete obj.data.merge_folder_display_name
          }
          this.saveConfBtnLoading = true
          obj.data['fileStartNumChange'] =
            this.form.fileStartNum !== this.oriForm.fileStartNum ||
            (!this.oriForm.subfolderName.templateString.includes(
              '文件数自增'
            ) &&
              this.form.subfolderName.templateString.includes('文件数自增')) ||
            (this.oriForm.subfolderName.templateString.includes('文件数自增') &&
              !this.form.subfolderName.templateString.includes('文件数自增'))
          this.$token('/save_customize_folder_setting', obj, (res) => {
            this.saveConfBtnLoading = false
            try {
              this.genFinalRecordFn(
                {
                  ...this.form,
                  setting_id: this.activeProductId,
                  nameArr: this.nameArr,
                },
                {
                  ...this.oriForm,
                  setting_id: this.oriSettingId,
                  nameArr: this.oriNameArr,
                }
              )
            } catch (error) {
              console.error(error)
            }
            if (res.status == 1) {
              this.$message({
                message: '保存成功',
                type: 'success',
                duration: 2000,
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
                duration: 2000,
              })
            }
          })
        } else {
          this.$message({
            message: '请完善信息',
            type: 'error',
            duration: 2000,
          })
          return false
        }
      })
    },
    // 获取用户文件设置
    getCustomizeFolderSetting(id) {
      this.showPageLoading = true
      this.$getByToken(
        '/load_customize_folder_setting',
        {
          setting_id: id,
        },
        (res) => {
          this.showPageLoading = false
          if (res.status === 1) {
            const data = res.data
            for (let key in this.form) {
              this.form[key] = data[key]
            }
            this.oriForm = JSON.parse(JSON.stringify(this.form))
            // 需要默认增加板件长宽的数据
            this.subfolderNameArr = this.form.subfolderName.templateString
              .split(this.form.delimiter)
              .map((str) => str.slice(1, str.length - 1))
            this.nameArr = data.wrapper_folder
            this.oriNameArr = JSON.parse(JSON.stringify(this.nameArr))
            // required_folders是前端保存后才存在的，如果不存在这个参数则使用folderKeyList进行初始化选中处理(folderKeyList选中的值都是页面上默认需要选中的)
            data.required_folders = data.required_folders || [...folderKeyList]
            this.setList.map((item) => {
              if (data.required_folders?.indexOf(item.type) === -1) {
                item.canMerge = false
                item.isFolder = false
              } else {
                item.canMerge = true
                item.isFolder = true
              }
            })
            this.getFileMergeOPtions()
          }
        }
      )
    },
    // 输入检验
    handleCheckInput() {
      this.form.max_file_name = this.form.max_file_name.replace(/[^\d]/g, '') // 去除数字以外的字符
      if (this.form.max_file_name === '0') {
        this.form.max_file_name = ''
      }
      if (this.form.max_file_name > 200) {
        this.form.max_file_name = 200
      }
    },
    dealSubFileName() {
      const allLabelList = this.subFolderTagList.map((i) => i.arr).flat(1)
      const dealArr = this.subfolderNameArr.map((nameStr) => {
        const label = allLabelList.find(
          (label) => label.label_name_show == nameStr
        )
        if (label) {
          return {
            templateStr: `{${label.label_name_show}}`,
            title: label.label_name_show,
            id: label.id,
          }
        } else {
          return ''
        }
      })
      const resArr = dealArr.filter((i) => i)
      const headerIds = {}
      resArr.forEach((re) => {
        headerIds[re.title] = re.id
      })
      this.form.subfolderName = {
        headerIds,
        templateString: resArr
          .map((re) => re.templateStr)
          .join(this.form.delimiter),
      }
    },
    handleFileName() {
      //  文件名不可以有 / \ ? < > " : * |
      this.form.merge_folder_display_name =
        this.form.merge_folder_display_name.replace(/[/|\\|?|<|>|"|:|*||]/g, '')
    },
  },
  mounted() {
    getElectronicSawLabel('merge_file').then((res) => {
      const subTagList = []
      for (const key in res.data) {
        const title = this.nameMap.get(key)
        if (title) {
          subTagList.push({
            title,
            arr: res.data[key],
          })
        }
      }
      this.subFolderTagList = subTagList
    })
  },
}
</script>

<style scoped lang="less">
::v-deep .ant-form-explain {
  margin-left: 160px;
}
.file-manage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  header {
    padding: 12px;
    text-align: right;
    background-color: #fff;
  }
  .content {
    flex: 1;
    padding: 24px;
    overflow: auto;
    background-color: #fff;
    .rename-config {
      .list-set {
        :deep(.ant-form-item-label) {
          width: 110px !important;
        }
        .subfolder-tip {
          top: -20px;
          right: -40px !important;
        }
      }
    }
  }
  .title {
    color: #000;
    font-weight: 700;
    font-size: 18px;
  }
  :deep(.ant-form) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  :deep(.ant-form-item) {
    display: flex;
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  :deep(.file_checkbox) {
    width: 160px;
    text-align: right;
    > span {
      color: rgba(0, 0, 0, 0.65) !important;
      padding-right: 10px;
      padding-left: 0px;
    }
    .ant-checkbox-checked::after {
      border: 0;
    }
  }
  .folder-format-box {
    .split-char {
      width: 300px;
    }
  }
  .empty-err {
    border-color: red !important;
  }
  .common-box {
    padding-bottom: 24px;
  }
}
</style>
